import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { environment } from "src/environments/environment";

declare let gtag: Function;
declare var FB: any;
declare global {
  interface Window {
    fbq: (...args: any[]) => void;
    _fbq?: any;
  }
}

declare var fbq: (...args: any[]) => void;

@Injectable({ providedIn: "root" })
export class GoogleAnalyticsService {
  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null
  ) {
    if (isPlatformBrowser(this.platformId)) {
      gtag("event", eventName, {
        eventCategory: eventCategory,
        eventLabel: eventLabel,
        eventAction: eventAction,
        eventValue: eventValue,
      });
    }
  }

  public event(eventName: string, params: {} = {}) {
    if (isPlatformBrowser(this.platformId)) {
      gtag("event", eventName, params);
      console.log(eventName, params);

      // if (typeof FB !== "undefined" && FB) {
      //   FB.AppEvents.logEvent(eventName, params);
      // }

      if (typeof fbq !== "undefined" && fbq) {
        fbq("track", eventName, params);
      }
    }
  }
  public fbevent(eventName: string, params: {} = {}) {
    if (isPlatformBrowser(this.platformId)) {
      if (typeof fbq !== "undefined" && fbq) {
        fbq("track", eventName, params);
      }
    }
  }

  public init(events?: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.listenForRouteChanges(events);
      try {
        this.loadFacebookSDK();
        this.loadFacebookPixel();
        this.loadGoogleAnalytics();
      } catch (ex) {
        console.error("Error initializing analytics services:", ex);
      }
    }
  }

  private loadFacebookSDK() {
    if ((window as any).FB) return; // Prevent duplicate loading

    (window as any).fbAsyncInit = function () {
      FB.init({
        appId: environment.facebookKey, // Your Facebook App ID
        cookie: true,
        xfbml: true,
        version: "v9.0",
      });
      FB.AppEvents.logPageView();
    };

    const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.src = "https://connect.facebook.net/en_US/sdk.js";
    document.head.appendChild(script);
  }

  private loadFacebookPixel() {
    if ((window as any).fbq) return; // Prevent duplicate loading

    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = true;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = true;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");

    fbq("init", environment.facebookKey); // Your Facebook Pixel ID
    fbq("track", "PageView");
  }

  private loadGoogleAnalytics() {
    const script1 = document.createElement("script");
    script1.async = true;
    script1.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsKey}`;
    document.head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${environment.googleAnalyticsKey}', {'send_page_view': false});
    `;
    document.head.appendChild(script2);
  }

  private listenForRouteChanges(events?: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          gtag("config", environment.googleAnalyticsKey, {
            page_path: event.urlAfterRedirects,
          });

          fbq("track", "PageView");

          if (events && events.eventName) {
            this.event(events.eventName, { eventAction: events.eventAction });
          }
        }
      });
    }
  }
}
