import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  AfterViewInit,
} from "@angular/core";
import { LoggerService } from "src/app/services/logger.service";
import { UserProfileService } from "src/app/services/user-profile.service";
import { MenuFoodCardService } from "./service/menu-food-card.service";
import { UtilityService } from "src/app/services/utility.service";
import { CartMessangerService } from "src/app/services/cart-messanger-service.service";
import { Router } from "@angular/router";
import {
  PRODUCT_DETAIL,
  WEB_ACCOUNT,
  WEB_CREATE_PROFILE,
  PROFILE_CREATE,
} from "src/app/constant/routes";
import { MatDialog } from "@angular/material/dialog";
import { ItemDetailWebComponent } from "./item-detail-web/item-detail-web.component";
import { GoogleAnalyticsService } from "src/app/services/google-analytics.service";
// import { ConsoleReporter } from "jasmine";

@Component({
  selector: "app-menu-food-card",
  templateUrl: "./menu-food-card.component.html",
  styleUrls: ["./menu-food-card.component.scss"],
})
export class MenuFoodCardComponent implements OnInit, AfterViewInit {
  itemDetails: any;
  restaurantOffline: boolean = false;
  fromSearch: number = 0;

  @Input() isFreeItem: boolean = false;
  @Input() currentFreeBiryaniPoint: number = 0;
  @Input() parent: string = null;
  @Output() addToCart = new EventEmitter<any>();
  @Input() webCartName: string = ""; //webCheckout
  @Output() webCartAction = new EventEmitter<any>();
  @Output() freeBiryaniCountChange = new EventEmitter<any>();
  @Input() set item(details) {
    this.itemDetails = details;
    // LoggerService.log(details);
  }

  @Input() freeBiryanis: any;
  freeBiryani: any;
  isUserGuest: boolean = false;
  constructor(
    private profileService: UserProfileService,
    private menuFoodService: MenuFoodCardService,
    private utility: UtilityService,
    private cartMessangerService: CartMessangerService,
    private router: Router,
    private changeRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private gaService: GoogleAnalyticsService
  ) {
    this.restaurantOffline = this.utility.restaurantOffline;
    // console.log(this.restaurantOffline);
  }
  ngAfterViewInit(): void {
    // throw new Error("Method not implemented.");
    // this.changeRef.detectChanges();
    // console.log(this.parent);
  }

  ngOnInit(): void { }

  async checkUserRole() {
    const userData = await this.profileService.getProfileDetail();
    console.log(userData);
    // if (userData.profileStep == 0) {
    //   this.utility.openDialog({message: 'Please create your profile', hideCancelButton:true}).subscribe( res => {
    //     if (this.utility.fromSidebar) {
    //       this.router.navigate(
    //         [
    //           {
    //             outlets: {
    //               web_sidebar: [WEB_ACCOUNT.fullUrl, WEB_CREATE_PROFILE.fullUrl],
    //             },
    //           },
    //         ],
    //         {
    //           skipLocationChange: true,
    //           // relativeTo: this.route.parent,
    //         }
    //       );
    //     } else {
    //       this.router.navigate([PROFILE_CREATE.fullUrl]);
    //     }
    //   })
    // }
    if (userData.isGuestUser === 1) {
      this.isUserGuest = true;
      return true;
    }
    this.isUserGuest = false;
    return false;
  }

  async addItemToCart() {
    this.checkForFreeBiryani();
    // const res = await this.checkUserRole();
    let itemObj = {
      restaurantId: this.utility.getRestaurantId(),
      restaurantItemId: this.itemDetails.itemInfo.restaurantItemId,
      quantity: 1,
      freeBiryani: this.freeBiryani,
    };

    if (
      this.parent == "home" ||
      this.parent == "search" ||
      this.parent == "freeBiryani" ||
      this.parent == "detail"
    ) {
      itemObj.restaurantItemId = this.itemDetails._id;
    }

    if (this.parent == "freeBiryani") {
      itemObj.freeBiryani = 1;
      if (this.currentFreeBiryaniPoint < 1) {
        this.utility.showAlert("You do not have free biryani points left.");
        return;
      }
      this.freeBiryaniCountChange.emit("added");
    }
    console.log(itemObj);

    const response = await this.menuFoodService
      .addItemToCart(itemObj, this.parent)
      .then((result) => {
        if (result) {
          console.log("add item to cart", result)

          this.itemDetails.cartItemCount = this.itemDetails.cartItemCount + 1;
          this.sendDataToAnalytics(
            this.itemDetails?.itemInfo?.itemId,
            this.itemDetails?.itemInfo?.itemName,
            this.itemDetails.price?.price,
            this.itemDetails.cartItemCount
          );
          this.cartMessangerService.sendMessage({ data: result.data });
          this.cartMessangerService.subject1.next(true);
          if (this.webCartName == "webCheckout") {
            this.webCartAction.emit({ type: "added", data: result.data });
          }
        }
      })
      .catch((err) => {
        //error
        this.utility.showAlert(err.error.message);
        // Remove free biryani point if error
        if (this.parent == "freeBiryani") {
          this.freeBiryaniCountChange.emit("removed");
        }
      });

    (window as any).Moengage.track_event("Add_To_Cart", {
      "Product ID": this.itemDetails?.itemInfo?.itemId, // string value
      "Product Name": this.itemDetails?.itemInfo?.itemName,
      Price: this.itemDetails.price?.price,
      "Product image URL": this.itemDetails?.itemInfo?.media?.mediaUrl, // string value        // string value
    });

    this.gaService.event("conversion", {
      send_to: "AW-878408708/Mj3DCOTGipECEITo7aID",
      value: this.itemDetails?.price?.price,
      currency: "INR",
    });
  }

  async removeFromCart() {
    // if (!this.isUserGuest) {
    const res = await this.checkUserRole();
    this.checkForFreeBiryani();
    let itemObj = {
      restaurantId: this.utility.getRestaurantId(),
      restaurantItemId: this.itemDetails.itemInfo.restaurantItemId,
      quantity: 1,
      freeBiryani: this.freeBiryani,
    };
    // console.log(this.parent);
    // console.log(this.itemDetails);

    if (
      this.parent == "home" ||
      this.parent == "search" ||
      this.parent == "freeBiryani" ||
      this.parent == "detail"
    ) {
      itemObj.restaurantItemId = this.itemDetails._id;
    }

    if (this.parent == "freeBiryani") {
      itemObj.freeBiryani = 1;
      this.freeBiryaniCountChange.emit("removed");
    }

    this.menuFoodService
      .removeItemToCart(itemObj)
      .then((res) => {
        if (res) {
          this.gaService.event("ITEM_REMOVED_FROM_CART", {});
          this.gaService.event("remove_from_cart", {
            currency: "INR",
            value: this.itemDetails?.price?.price,
            items: [
              {
                item_id: this.itemDetails?.itemInfo?.itemId,
                item_name: this.itemDetails?.itemInfo?.itemName,
              },
            ],
          });
          this.itemDetails.cartItemCount = this.itemDetails.cartItemCount - 1;
          this.cartMessangerService.sendMessage({ data: res.data });
          this.cartMessangerService.subject1.next(false);

          if (this.webCartName == "webCheckout") {
            this.webCartAction.emit({ type: "removed", data: res.data });
          }
        }
      })
      .catch((err) => {
        // error
      });

    (window as any).Moengage.track_event("Remove_from_Cart", {
      "Product ID": this.itemDetails?.itemInfo?.itemId, // string value
      "Product Name": this.itemDetails?.itemInfo?.itemName,
      Price: this.itemDetails?.price?.price,
      "Product image URL": this.itemDetails?.itemInfo?.media?.mediaUrl,
    });
    // }
  }

  sendDataToAnalytics(productId?: string, productName?: string, price?: number,qty?: number) {
    console.log(this.parent);
    if (this.parent == "explore-menu") {
      console.log(this.parent);
      this.gaService.event("ITEM_ADDED_FROM_EXPLORE", {});
    } else if (this.parent == "home") {
      this.gaService.event("ITEM_ADDED_FROM_HOME", {});
    }
    this.gaService.event("ITEM_ADDED_TO_CART", {});
    this.gaService.event("add_to_cart", {
      currency: "INR",
      value: price,
      items: [
        {
          item_id: productId,
          item_name: productName,
        },
      ],
    })
    this.gaService.fbevent("AddToCart", {
      currency: "INR",
      value: price,
      contents: [
        {
          id: productName,
          qty: qty,
        },
      ],
    })
  }

  checkForFreeBiryani() {
    if (this.freeBiryani && this.freeBiryanis.length) {
      const freeBiryaniItem = this.freeBiryanis.find(
        (item) => item._id == this.itemDetails._id
      );
      this.freeBiryani =
        freeBiryaniItem && freeBiryaniItem.freeBiryani === true ? 1 : 0;
      console.log(this.freeBiryani);
    } else {
      this.freeBiryani = 0;
    }
  }

  routeToDetails() {
    if (this.parent == "freeBiryani") {
      return;
    }
    console.log(this.itemDetails, "itemdetails");
    //if (this.utility.fromSidebar) {
    const dialogRef = this.dialog.open(ItemDetailWebComponent, {
      width: "560px",
      disableClose: false,
      autoFocus: false,
      restoreFocus: false,
      data: {
        id: this.itemDetails.itemInfo.restaurantItemId ? this.itemDetails.itemInfo.restaurantItemId : this.itemDetails._id,
        productId: this.itemDetails.itemInfo.itemId,
        parent: this.parent,
        point: this.currentFreeBiryaniPoint,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.utility.cartUpdatedFromPopup.next(true);
      }
    });


    // *************
    //} else {
    //  if (this.parent == "search") {
    //    this.fromSearch = 1;
    //  }
    //  this.router.navigate(
    //    [PRODUCT_DETAIL.fullUrl, this.itemDetails.itemInfo.itemId],
    //    { queryParams: { fromSearch: this.fromSearch } }
    //  );
    //}
  }

  async addToFavourite(itemId: string) {
    let restaurantId = this.utility.getRestaurantId();
    const userData = await this.profileService.getProfileDetail();
    if (userData.isGuestUser) {
      this.utility.guestPopup();
    } else {
      this.menuFoodService
        .addFavourite({ restaurantId, itemId })
        .then((data) => {
          console.log(this.itemDetails, data);
          this.gaService.event("ITEM_ADDED_TO_FAVOURITE", {});
          this.gaService.event("add_to_wishlist", {
            currency: "INR",
            value: this.itemDetails?.price?.price,
            items: [
              {
                item_id: this.itemDetails?.itemInfo?.itemId,
                item_name: this.itemDetails?.itemInfo?.itemName,
              },
            ],
          });
          this.itemDetails.isFavourite = 1;
          this.cartMessangerService.sendMessage(false);
        })
        .catch((err) => { });
      (window as any).Moengage.track_event("Add_To_Favourite", {
        "Product ID": this.itemDetails.itemInfo.itemId, // string value
        "Product Name": this.itemDetails.itemInfo.itemName, // string value
      });
    }
  }

  removeFromFavourite(itemId: string) {
    let restaurantId = this.utility.getRestaurantId();
    this.menuFoodService
      .removeFavourite({ restaurantId, itemId })
      .then((data) => {
        this.gaService.event("ITEM_REMOVED_FROM_FAVOURITE", {});
        this.itemDetails.isFavourite = 0;

        this.cartMessangerService.sendMessage(false);
      })
      .catch((err) => { });
  }
}
